.frame {
    position: relative;
    top: 0;
    width: 400px;
    margin: auto;
    height: 300px;
    overflow: hidden;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .thermostat {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 18px;
    left: 100px;
    background: #F2F2F2;
    border-radius: 50%;
    box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.8);
  }
  
  .thermostat .control {
    position: absolute;
    z-index: 5;
    width: 130px;
    height: 130px;
    top: 25%;
    left: 35px;
    background: #E6E6E6;
    border-radius: 50%;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.7);
  }
  .thermostat .control .temp_outside {
    position: absolute;
    top: 25px;
    left: 6px;
    right: 0;
    text-align: center;
    font-weight: 300;
    font-size: 1rem;
  }
  .thermostat .control .temp_room {
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: 400;
    font-size: 60px;
    line-height: 60px;
    color: #873183;
    letter-spacing: -8px;
    padding-right: 12px;
    opacity: 1;
    transform: translateX(0);
    transition: all .5s ease-in-out;
  }
  .thermostat .control .temp_room span {
    position: absolute;
    top: 0;
    right: 37px;
    font-size: 2rem;
    line-height: 34px;
    padding: 3px 0 0 7px;
      color: #8e2275;
  }
  .room {
    position: absolute;
    bottom: 0;
    left: 11px;
    right: 0;
    text-align: center;
    font-weight: 600;
    font-size: 28px;
  }
  .thermostat .ring {
    position: absolute;
    width: 180px;
    height: 180px;
    top: 10px;
    left: 10px;
    background-image: linear-gradient(90deg, rgb(237, 7, 36) 0%, rgb(237, 139, 13) 17%, rgb(255, 254, 0) 64%, rgb(178, 244, 171) 83%, #50C878 100%);
    border-radius: 50%;
    box-shadow: inset 2px 4px 4px 0px rgba(0, 0, 0, 0.3);
  }
  .thermostat .ring .bottom_overlay {
    position: absolute;
    width: 95px;
    height: 95px;
    top: 50%;
    left: 50%;
    background: #F2F2F2;
    transform-origin: 0 0;
    transform: rotate(45deg);
    border-radius: 0 0 95px 0;
  }
  
  #slider {
      position: absolute;
      width: 170px;
      height: 150px;
      top: 20%;
      left: 32%;
      z-index: 1000;
  }
  
  #slider .rs-border  {
      border-color: transparent;
  }
  .rs-control .rs-range-color, .rs-control .rs-path-color, .rs-control .rs-bg-color {
      background-color: rgba(0, 0, 0, 0);
  }
  
  .rs-tooltip.edit, .rs-tooltip .rs-input, .rs-tooltip-text {
      font-family: rubik, helvetica, sans-serif;
      font-size: 3.3rem;
      background: transparent;
      color: #8e2275;
      font-weight: 400;
      top: 65%;
      height: 3.9rem;
      padding: 0 !important;
      width: 4.5rem;
      display: none !important;
  }
  #slider:hover .rs-tooltip, .rs-tooltip:focus, .rs-tooltip-text:focus {
      border: none;
      transform: scale(1.1);
      transition: .1s;
  }
  #slider .rs-transition  {
    transition-timing-function: cubic-bezier(1.000, -0.530, 0.405, 1.425);
  }
  .instructions {
      position: absolute;
      bottom: .5rem;
      color: $primary-color;
      font-size: 13px;
      width: 85%;
      left: 10%;
      font-weight: 400;
      letter-spacing: .05rem;
      line-height: 1.3;
      text-align: center;
      bottom: 0;
  }
  .fas {
      animation: pulse 1s infinite;
  }
  @keyframes pulse {
      50% {
          transform: scale(.9);
      }
  }

  .rs-tooltip-text{
    left: 40% !important;
    font-size: 30px !important;
  }

  #slider .rs-transition  {
    transition-timing-function: cubic-bezier(0,0,1,3);
  }

  #slider .rs-handle  {
    background-color: transparent;
    border: 8px solid transparent;
    border-right-color: black;
    margin: -6px 0px 0px -20px !important;
    border-width: 6px 104px 6px 4px;
  }
  #slider .rs-handle:before  {
      display: block;
      content: " ";
      position: absolute;
      height: 22px;
      width: 22px;
      background: black;
      right: -11px;
      bottom: -11px;
      border-radius: 100px;
  }

  .rs-disabled{
    opacity: 1.0 !important;
  }

